import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { programRequest } from "../actions/orderAction";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import ClaimedGiftCards from "../components/ClaimedGiftCards";
import PendingOfferTable from "../components/PendingOfferTable";
import FailedOfferTable from "../components/FailedOfferTable";
import {
  logOut,
  updateState,
} from "../actions/loginAction";
import useIdleTimeout from "../components/useIdleTimeout";
import IdleOverLay from "../components/IdleOverlay";

const UserHomeScreen = () => {
  // Retrieve the user's id from the URL parameters
  const { id } = useParams();
  const dispatch = useDispatch();
  // Create state variable to show/hide the idle timeout overlay
  const [showIdleOverlay, setShowIdleOverlay] = useState(false);
  const history = useHistory();
  // Get the loginDetails from the Redux store and destructure the loading and userInfo properties
  const loginDetails = useSelector((state) => state.loginDetails);
  const { loading, userInfo } = loginDetails;
  // Get the orderResults from the Redux store and destructure the orderLoading, orderError, and orderInfo properties
  const orderResults = useSelector((state) => state.orderResults);
  const { orderLoading, orderError } = orderResults;

  // Shows the idle overlay warning the user that they will be logged out soon
  const showIdlePrompt = () => {
    setShowIdleOverlay(true);
  };

  // Logs the user out if they click the "Log Out" button
  const handleLogout = () => {
    if (userInfo.id === id) {
      localStorage.removeItem("reward-" + id);
      localStorage.removeItem("ex-" + id);
    }
    dispatch(logOut());
    history.push("/");
    setShowIdleOverlay(false);
  };

  // Use the idle timeout hook to track user activity
  const { idle, idleTimer } = useIdleTimeout({
    promptBeforeIdle: showIdlePrompt,
    idleTime: 5, // User is logged out after this many minutes of inactivity
    doWhenIdle: handleLogout,
  });

  // Closes the overlay is the user clicks on the "Stay In" button
  const handleStay = () => {
    setShowIdleOverlay(false);
    idleTimer.reset();
  };

  // Handles the order interaction
  useEffect(() => {
    // Retrieve the rewards ID from local storage
    let rewardsId = JSON.parse(localStorage.getItem("reward-" + id));

    // If we have a rewards ID and we haven't timed out, update the Redux store with the rewards ID
    if (rewardsId && !idle) {
      dispatch(updateState(rewardsId));
    }
  }, [dispatch, id, idle]);

  // Handles changes to userInfo
  useEffect(() => {
    // If the Redux store has the user's rewards ID (meaning they logged in successfully),
    // extract it from Redux and store it in local storage to persist it across page refreshes
    if (userInfo && userInfo.rewardsId && !idle) {
      localStorage.setItem("reward-" + userInfo.id, JSON.stringify(userInfo));
    }
  }, [dispatch, idle, userInfo, history, id, loginDetails]);
  //}, [dispatch, userInfo, history, redirect, show, id, orders, loginDetails]);

  // Handle when the user clicks the "Claim" button
  const onClaimSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem("ex-" + id);
    dispatch(programRequest());
  };

  return (
    <div className="gcotg-userhome-top-level">
      <Container fluid className="no-padding">
        <Row>
          <Col>
            <Header id={id} />
          </Col>
        </Row>
        <div className="gcotg-user-home-content">
          <Row>
            <Col className="userhome-main-content-col">
              {!loading && (
                <div>
                  <h2 className="userhome-greeting">
                    Welcome, {userInfo?.firstName} {userInfo?.lastName}!
                  </h2>
                </div>
              )}
              {(orderLoading || loading) && (
                <div>
                  <Loader/>
                  <strong>
                    Processing can take several minutes. Please be patient.
                  </strong>
                </div>
              )}
              {orderError && (
                <div>
                  <p className="order-error-msg">{orderError}</p>
                </div>
              )}
              <div>
                <div>
                  {showIdleOverlay && (
                    <IdleOverLay
                      show={showIdleOverlay}
                      handleStay={handleStay}
                      handleLogout={handleLogout}
                    />
                  )}
                </div>
                {process.env.REACT_APP_THIRD_LOGIN_FIELD === "ticketNum" &&
                  userInfo?.giftcardList?.length === 0 &&
                  userInfo?.giftcardListPending?.length === 0 &&
                  userInfo?.giftcardListFailed?.length === 0 &&
                  userInfo?.giftcardListHistory?.length === 0 &&
                  (
                  <div>
                    <p className="no-offers-message">
                      There are no offers available for offer code #{userInfo?.ticketNum}
                    </p>
                  </div>
                )}
                {(process.env.REACT_APP_THIRD_LOGIN_FIELD === "zipcode" ||
                  process.env.REACT_APP_THIRD_LOGIN_FIELD === "none") &&
                  userInfo?.giftcardList?.length === 0 &&
                  userInfo?.giftcardListPending?.length === 0 &&
                  userInfo?.giftcardListFailed?.length === 0 &&
                  userInfo?.giftcardListHistory?.length === 0 &&
                  (
                    <div>
                      <p className="no-offers-message">
                        No current offers available.<br />Please check the offer instructions for valid{" "}
                        redemption dates.
                      </p>
                    </div>
                  )}
                {userInfo?.giftcardList?.length > 0 && (
                  <div className="new-offers-outer-div">
                    <div className="new-offers-div">
                      <div className="gcotg-info-message">{userInfo?.message}</div>
                      {!orderError &&
                        userInfo?.giftcardList?.length > 0 && (
                          <p className="new-offers-message">
                            You have new gift card offers
                          </p>
                        )}
                      {!orderError &&
                        userInfo?.giftcardList?.length > 0 &&
                        userInfo?.giftcardList.map((giftCard) => (
                          <div key={giftCard}>
                            <h4 className="new-offers-item">
                              ${giftCard.price} {giftCard.promotionName} gift card
                            </h4>
                          </div>
                        ))}
                    </div>
                    <div>
                      {!orderLoading &&
                        !orderError &&
                        userInfo?.giftcardList.length > 0 && (
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={(e) => onClaimSubmit(e)}
                            className="gcotg-button"
                          >
                            CLICK HERE TO CLAIM
                          </Button>
                        )}
                    </div>
                  </div>
                )}
                <div>
                  {userInfo?.giftcardListPending.length ? (
                    <PendingOfferTable userInfo={userInfo}/>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div>
                {userInfo?.giftcardListFailed.length ? (
                  <FailedOfferTable userInfo={userInfo}/>
                ) : (
                  ""
                )}
              </div>
              <div>
                {userInfo?.giftcardListHistory.length ? (
                  <ClaimedGiftCards userInfo={userInfo}/>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <Footer userInfo={userInfo} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserHomeScreen;
