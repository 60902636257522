import React from "react";
import { Container } from "react-bootstrap";

const Footer = ({ userInfo }) => {
  const rewardsId = userInfo?.rewardsId;

  return (
    <Container fluid className="gcotg-footer">
      <a href={`mailto:cs@giftcardselection.com?subject=Gift card inquiry from Ak-Chin guest #${rewardsId}`}>
        Click here to email customer service
      </a>
    </Container>
  );
};

export default Footer;
