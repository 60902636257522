/*
import {
  BACK_OFFICE_PROPERTY_LIST_REQUEST,
  BACK_OFFICE_PROPERTY_LIST_SUCCESS,
  BACK_OFFICE_PROPERTY_LIST_FAIL,
  BACK_OFFICE_CATALOG_REQUEST,
  BACK_OFFICE_CATALOG_SUCCESS,
  BACK_OFFICE_CATALOG_FAIL,
  BACK_OFFICE_RETRY_OFFER_REQUEST,
  BACK_OFFICE_RETRY_OFFER_SUCCESS,
  BACK_OFFICE_RETRY_OFFER_FAIL,
  BACK_OFFICE_RESET_REQUEST,
} from "../constants/backOfficeActions";
*/

import * as boConstants from "../constants/backOfficeActions";

import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export const sendFileData = (file, e) => async (dispatch) => {

  console.log("The file data");
  console.log(file);
  let formDatas = new FormData(e.target);
  let toastId = toast.loading("Please wait. Processing file.");

  try {
    let response = await axios({
      method: "post",
      url: process.env.REACT_APP_EXPORT_EXCEL_URL,
      data: formDatas,
      headers: {"Content-Type": "multipart/form-data"},
    });

    //handle success
    toast.dismiss(toastId);
    toast.success("Success for file " + file.name +".\n" +
      response.data.message, {progress: 0.99});

    // Now that the file has been uploaded, call the get loads endpoint to refresh the list
    dispatch(getLoads());

  } catch (error) {

    const message =
      (error.response && error.response.data) ||
      error.response ||
      error.message ||
      error.toString();

    toast.dismiss(toastId);
    toast.error("Failed to load file " + file.name + ".\n" +
      "Error: " + message, {
      progress: 0.99,
    });

    console.log("AFTER THE TOAST.");
  }
};

export const toggleLoads = (loadsToToggle)=> async (dispatch) => {

  // Convert loadsToToggle to an array of key-value pairs
  // loadsToToggle contains the list of loads that the user has changed
  const loadsArray = Object.entries(loadsToToggle);

  // Loop through the array of key-value pairs
  for (let [key, value] of loadsArray) {
    // If value is set to true, call the activate endpoint with the load ID
    if (value === true) {
      try {
        let activateResponse = await axios({
          url: process.env.REACT_APP_BACK_OFFICE_ACTIVATE_LOAD_URL,
          params: { loadId: key },
          method: "post",
        });
        toast.success(activateResponse.data, { progress: 0.99 });
      } catch (error) {
        toast.error("Load " + key + " failed to activate with error=" + error, { progress: 0.99 });
      }
    }
    else {
      // If value is set to false, call the deactivate endpoint with the load ID
      try {
        let deactivateResponse = await axios({
          url: process.env.REACT_APP_BACK_OFFICE_DEACTIVATE_LOAD_URL,
          params: { loadId: key },
          method: "post",
        });
        toast.success(deactivateResponse.data, { progress: 0.99 });
      } catch (error) {
        toast.error("Deactivation of load " + key + " failed with error=" + error, { progress: 0.99 });
      }
    }
  }

  // Now that all loads have been toggled, call the get loads endpoint to refresh the list
  dispatch(getLoads());
};

// Calls the back end to retrieve the property list
export const getPropertyList = () => async (dispatch) => {
  try {
    dispatch({
      type: boConstants.BACK_OFFICE_PROPERTY_LIST_REQUEST,
    });

    let propertyResponse = await axios({
      url: process.env.REACT_APP_GET_PROPERTY_LIST_URL,
      method: "GET",
      responseType: "json",
    });

    dispatch({
      type: boConstants.BACK_OFFICE_PROPERTY_LIST_SUCCESS,
      payload: propertyResponse.data,
    });
  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    dispatch({
      type: boConstants.BACK_OFFICE_PROPERTY_LIST_FAIL,
      error: message,
    });
  }
};

// Calls the back end refresh catalog endpoint
export const refreshCatalog = () => async (dispatch) => {
  try {
    // First set the state to requesting the catalog
    dispatch({
      type: boConstants.BACK_OFFICE_CATALOG_REQUEST,
    });

    // Dispatch the request to refresh the catalog and wait for a response
    let catalogResponse = await axios({
      url: process.env.REACT_APP_BACK_OFFICE_REFRESH_CATALOG_URL,
      method: "post",
    });

    // If the refresh was successful, set the state to success
    dispatch({
      type: boConstants.BACK_OFFICE_CATALOG_SUCCESS,
      payload: catalogResponse.data,
    });

    toast.success("Catalog refresh successful", { progress: 0.99 });

    // Now refresh the brand catalog list
    dispatch(getBrandCatalog());

  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    toast.error("Catalog refresh failed with error '" + message + "'", { progress: 0.99 });

    // If the refresh fails, set the state to fail
    dispatch({
      type: boConstants.BACK_OFFICE_CATALOG_FAIL,
      error: message,
    });
  }
};

export const resetBackOffice = () => async (dispatch) => {
  // Set the state to reset request
  dispatch({
    type: boConstants.BACK_OFFICE_RESET_REQUEST,
  });
};


// Calls the back end to retrieve the list of failed offers
export const getIncompleteOffers = (pageNumber = 0,
                                    pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                                    showTestOffers) => async (dispatch) => {
  try {
    dispatch({
      type: boConstants.BACK_OFFICE_GET_INCOMPLETE_OFFERS_REQUEST,
    });

    let serverResponse = await axios({
      url: process.env.REACT_APP_BACK_OFFICE_GET_INCOMPLETE_OFFERS_URL,
      params: {
        showTestOffers: showTestOffers,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
      method: "GET",
      responseType: "json",
    });

    dispatch({
      type: boConstants.BACK_OFFICE_GET_INCOMPLETE_OFFERS_SUCCESS,
      payload: serverResponse.data,
    });
  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    dispatch({
      type: boConstants.BACK_OFFICE_GET_INCOMPLETE_OFFERS_FAIL,
      error: message,
    });
  }
};

export const getLoads = (pageNumber = 0, pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE) => async (dispatch) => {
  try {
    dispatch({
      type: boConstants.BACK_OFFICE_GET_LOADS_REQUEST,
    });

    const serverResponse = await axios({
      url: process.env.REACT_APP_BACK_OFFICE_GET_LOADS_URL,
      method: "POST",
      data:  {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });

    dispatch({
      type: boConstants.BACK_OFFICE_GET_LOADS_SUCCESS,
      payload: serverResponse.data,
    });
  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    dispatch({
      type: boConstants.BACK_OFFICE_GET_LOADS_FAIL,
      error: message,
    });

  }
};


export const getPlayerOffers = (pageNumber = 0,
                                pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE,
                                {playerId}) => async (dispatch) => {

  try {
    dispatch({
      type: boConstants.BACK_OFFICE_GET_PLAYER_OFFERS_REQUEST,
    });

    const serverResponse = await axios({
      url: process.env.REACT_APP_BACK_OFFICE_GET_PLAYER_OFFERS_URL,
      method: "GET",
      params:  {
        playerId: playerId,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });

    dispatch({
      type: boConstants.BACK_OFFICE_GET_PLAYER_OFERS_SUCCESS,
      payload: serverResponse.data,
    });
  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    toast.error("Failed to retrieve player offers with error '" + message + "'", { progress: 0.99 });

    dispatch({
      type: boConstants.BACK_OFFICE_GET_PLAYER_OFFERS_FAIL,
      error: message,
    });

  }
};


export const forceClaim = (awardId,
                           playerId,
                           pageNumber = 0,
                           pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE) => async (dispatch) => {
  try {
    dispatch({
      type: boConstants.BACK_OFFICE_FORCE_CLAIM_REQUEST,
    });

    const serverResponse = await axios({
      url: process.env.REACT_APP_BACK_OFFICE_FORCE_CLAIM_URL,
      method: "POST",
      params:  {
        awardId: awardId,
      },
    });

    dispatch({
      type: boConstants.BACK_OFFICE_FORCE_CLAIM_SUCCESS,
      payload: serverResponse.data,
    });

    toast.success(serverResponse.data, { progress: 0.99 });

    // Now that the offer has been forced, call the get offers endpoint to refresh the list
    dispatch(getPlayerOffers(pageNumber, pageSize, {playerId}));

  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    toast.error("Force claim failed with error '" + message + "'", { progress: 0.99 });

    dispatch({
      type: boConstants.BACK_OFFICE_FORCE_CLAIM_FAIL,
      error: message,
    });

  }
};

// Fetches the brand catalog from the server in a paginated manner.
// Defaults to page 0 and page size 50 if not specified.
export const getBrandCatalog = (pageNumber = 0,
                                pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE) => async (dispatch) => {

  try {
    dispatch({
      type: boConstants.BACK_OFFICE_GET_BRAND_CATALOG_REQUEST,
    });

    const serverResponse = await axios({
      url: process.env.REACT_APP_BACK_OFFICE_GET_BRAND_CATALOG_URL,
      method: "GET",
      params:  {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });

    dispatch({
      type: boConstants.BACK_OFFICE_GET_BRAND_CATALOG_SUCCESS,
      payload: serverResponse.data,
    });
  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    dispatch({
      type: boConstants.BACK_OFFICE_GET_BRAND_CATALOG_FAIL,
      error: message,
    });

  }
};


export const cancelOffer = (awardId,
                           showTestOffers,
                           pageNumber = 0,
                           pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE) => async (dispatch) => {

  try {
    dispatch({
      type: boConstants.BACK_OFFICE_CANCEL_OFFER_REQUEST,
    });


    const serverResponse = await axios({
      url: process.env.REACT_APP_BACK_OFFICE_CANCEL_OFFER_URL,
      method: "POST",
      params:  {
        awardId: awardId,
      },
    });

    dispatch({
      type: boConstants.BACK_OFFICE_CANCEL_OFFER_SUCCESS,
      payload: serverResponse.data,
    });

    toast.success(serverResponse.data, { progress: 0.99 });

    // Now that the offer has been canceled, call the get problem offers endpoint to refresh the list
    dispatch(getIncompleteOffers(pageNumber, pageSize, showTestOffers));

  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    // If the message contains the words "Could not lock offer", display a different message
    if (message.includes("Could not lock offer")) {
      toast.error("This offer is currently locked by another process, likely because the offer is " +
        "being retried. Wait a few minutes and try again.", { progress: 0.99 });
    }
    else {
      toast.error("Cancel offer failed with error '" + message + "'", { progress: 0.99 });
    }

    dispatch({
      type: boConstants.BACK_OFFICE_CANCEL_OFFER_FAIL,
      error: message,
    });

  }
};


export const retryOffer = (awardId,
                            showTestOffers,
                            pageNumber = 0,
                            pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE) => async (dispatch) => {

  try {
    dispatch({
      type: boConstants.BACK_OFFICE_RETRY_OFFER_REQUEST,
    });


    const serverResponse = await axios({
      url: process.env.REACT_APP_BACK_OFFICE_RETRY_OFFER_URL,
      method: "POST",
      params:  {
        awardId: awardId,
      },
    });

    dispatch({
      type: boConstants.BACK_OFFICE_RETRY_OFFER_SUCCESS,
      payload: serverResponse.data,
    });

    toast.success(serverResponse.data, { progress: 0.99 });

    // Now that the offer has been canceled, call the get problem offers endpoint to refresh the list
    dispatch(getIncompleteOffers(pageNumber, pageSize, showTestOffers));

  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    // If the message contains the words "Could not lock offer", display a different message
    if (message.includes("Could not lock offer")) {
      toast.error("This offer is currently locked by another process." +
        "Wait a few minutes and try again.", { progress: 0.99 });
    }
    else {
      toast.error("Retry offer failed with error '" + message + "'", { progress: 0.99 });
    }

    dispatch({
      type: boConstants.BACK_OFFICE_RETRY_OFFER_FAIL,
      error: message,
    });

  }
};

export const getPlayerProfile = (playerId) => async (dispatch) => {

  console.log("getPlayerProfile called with playerId=" + playerId);

  try {
    dispatch({
      type: boConstants.BACK_OFFICE_GET_PLAYER_PROFILE_REQUEST,
    });

    const serverResponse = await axios({
      url: process.env.REACT_APP_BACK_OFFICE_GET_PLAYER_PROFILE_URL,
      method: "GET",
      params:  {
        playerId: playerId,
      },
    });

    dispatch({
      type: boConstants.BACK_OFFICE_GET_PLAYER_PROFILE_SUCCESS,
      payload: serverResponse.data,
    });
  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    dispatch({
      type: boConstants.BACK_OFFICE_GET_PLAYER_PROFILE_FAIL,
      error: message,
    });

  }
};

export const reactivatePlayer = (playerId) => async (dispatch) => {

  try {
    dispatch({
      type: boConstants.BACK_OFFICE_REACTIVATE_PLAYER_REQUEST,
    });

    const serverResponse = await axios({
      url: process.env.REACT_APP_BACK_OFFICE_REACTIVATE_PLAYER_URL,
      method: "POST",
      params:  {
        playerId: playerId,
      },
    });

    dispatch({
      type: boConstants.BACK_OFFICE_REACTIVATE_PLAYER_SUCCESS,
      payload: serverResponse.data,
    });

    toast.success("Player " + playerId
      + " successfully reactivated.", { progress: 0.99 });

  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    toast.error("Failed to reactivate player " + playerId
      + " with error '" + message + "'", { progress: 0.99 });

    dispatch({
      type: boConstants.BACK_OFFICE_REACTIVATE_PLAYER_FAIL,
      error: message,
    });

  }
};


