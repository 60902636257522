import {
  GET_PROGRAMS_REQUEST,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAIL,
  GET_PROGRAMS_NETWORK_ERROR,
  UPDATE_ORDER_STATE,
  GET_RESPONSE_STATUS,
  EMPTY_ORDERS,
  RESET_ORDERS,
} from "../constants/getOrderActions";

const initialState = {
  networkFail: false,
  serverFail: false,
  orderLoading: false,
  orderError: "",
  orderStatus: "",
};

export const userOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROGRAMS_REQUEST:
      return { ...state, orderLoading: true };
    case GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        networkFail: false,
        serverFail: false,
        orderLoading: false,
        orderError: "",
      };
    case UPDATE_ORDER_STATE:
      return {
        ...state,
        networkFail: false,
        orderLoading: false,
      };
    case EMPTY_ORDERS:
      return {};
    case GET_PROGRAMS_FAIL:
      return {
        ...state,
        serverFail: true,
        orderLoading: false,
        orderError: action.payload,
      };
    case GET_PROGRAMS_NETWORK_ERROR:
      return {
        ...state,
        networkFail: true,
        orderLoading: false,
        orderError: action.payload,
      };
    case GET_RESPONSE_STATUS:
      return { ...state, orderStatus: action.payload };
    case RESET_ORDERS:
      return { ...initialState };
    default:
      return { ...state };
  }
};
