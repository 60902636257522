export const BACK_OFFICE_PROPERTY_LIST_REQUEST =
  "BACK_OFFICE_PROPERTY_LIST_REQUEST";
export const BACK_OFFICE_PROPERTY_LIST_SUCCESS =
  "BACK_OFFICE_PROPERTY_LIST_SUCCESS";
export const BACK_OFFICE_PROPERTY_LIST_FAIL = "BACK_OFFICE_PROPERTY_LIST_FAIL";

export const BACK_OFFICE_CATALOG_REQUEST = "BACK_OFFICE_CATALOG_REQUEST";
export const BACK_OFFICE_CATALOG_SUCCESS = "BACK_OFFICE_CATALOG_SUCCESS";
export const BACK_OFFICE_CATALOG_FAIL = "BACK_OFFICE_CATALOG_FAIL";
export const BACK_OFFICE_GET_BRAND_CATALOG_REQUEST = "BACK_OFFICE_GET_BRAND_CATALOG_REQUEST";
export const BACK_OFFICE_GET_BRAND_CATALOG_SUCCESS = "BACK_OFFICE_GET_BRAND_CATALOG_SUCCESS";
export const BACK_OFFICE_GET_BRAND_CATALOG_FAIL = "BACK_OFFICE_GET_BRAND_CATALOG_FAIL";

export const USER_REGISTER_REQUEST = "BACK_OFFICE_LOGIN_REQUEST";
export const USER_REGISTER_SUCCESS = "BACK_OFFICE_LOGIN_SUCCESS";
export const USER_REGISTER_FAIL = "BACK_OFFICE_LOGIN_FAIL";

export const BACK_OFFICE_RETRY_OFFER_REQUEST =
  "BACK_OFFICE_RETRY_OFFER_REQUEST";
export const BACK_OFFICE_RETRY_OFFER_SUCCESS =
  "BACK_OFFICE_RETRY_OFFER_SUCCESS";
export const BACK_OFFICE_RETRY_OFFER_FAIL = "BACK_OFFICE_RETRY_OFFER_FAIL";

export const BACK_OFFICE_RESET_REQUEST = "BACK_OFFICE_RESET_REQUEST";

export const BACK_OFFICE_CANCEL_RETRY_REQUEST =
  "BACK_OFFICE_CANCEL_RETRY_REQUEST";
export const BACK_OFFICE_CANCEL_RETRY_SUCCESS =
  "BACK_OFFICE_CANCEL_RETRY_SUCCESS";
export const BACK_OFFICE_CANCEL_RETRY_FAIL = "BACK_OFFICE_CANCEL_RETRY_FAIL";

export const BACK_OFFICE_GET_FAILED_OFFERS_REQUEST =
  "BACK_OFFICE_GET_FAILED_OFFERS_REQUEST";
export const BACK_OFFICE_GET_FAILED_OFFERS_SUCCESS =
  "BACK_OFFICE_GET_FAILED_OFFERS_SUCCESS";
export const BACK_OFFICE_GET_FAILED_OFFERS_FAIL =
  "BACK_OFFICE_GET_FAILED_OFFERS_FAIL";

export const BACK_OFFICE_GET_INCOMPLETE_OFFERS_REQUEST =
  "BACK_OFFICE_GET_INCOMPLETE_OFFERS_REQUEST";
export const BACK_OFFICE_GET_INCOMPLETE_OFFERS_SUCCESS =
  "BACK_OFFICE_GET_INCOMPLETE_OFFERS_SUCCESS";
export const BACK_OFFICE_GET_INCOMPLETE_OFFERS_FAIL =
  "BACK_OFFICE_GET_INCOMPLETE_OFFERS_FAIL";

export const BACK_OFFICE_GET_LOADS_REQUEST =
  "BACK_OFFICE_GET_LOADS_REQUEST";
export const BACK_OFFICE_GET_LOADS_SUCCESS =
  "BACK_OFFICE_GET_LOADS_SUCCESS";
export const BACK_OFFICE_GET_LOADS_FAIL =
  "BACK_OFFICE_GET_LOADS_FAIL";


export const BACK_OFFICE_GET_PLAYER_OFFERS_REQUEST =
  "BACK_OFFICE_GET_PLAYER_OFFERS_REQUEST";
export const BACK_OFFICE_GET_PLAYER_OFERS_SUCCESS =
  "BACK_OFFICE_GET_PLAYER_OFERS_SUCCESS";
export const BACK_OFFICE_GET_PLAYER_OFFERS_FAIL =
  "BACK_OFFICE_GET_PLAYER_OFFERS_FAIL";


export const BACK_OFFICE_FORCE_CLAIM_REQUEST =
  "BACK_OFFICE_FORCE_CLAIM_REQUEST";
export const BACK_OFFICE_FORCE_CLAIM_SUCCESS =
  "BACK_OFFICE_FORCE_CLAIM_SUCCESS";
export const BACK_OFFICE_FORCE_CLAIM_FAIL =
  "BACK_OFFICE_FORCE_CLAIM_FAIL";

export const BACK_OFFICE_CANCEL_OFFER_REQUEST =
  "BACK_OFFICE_CANCEL_OFFER_REQUEST";
export const BACK_OFFICE_CANCEL_OFFER_SUCCESS =
  "BACK_OFFICE_CANCEL_OFFER_SUCCESS";
export const BACK_OFFICE_CANCEL_OFFER_FAIL =
  "BACK_OFFICE_CANCEL_OFFER_FAIL";

export const BACK_OFFICE_GET_PLAYER_PROFILE_REQUEST =
  "BACK_OFFICE_GET_PLAYER_PROFILE_REQUEST";
export const BACK_OFFICE_GET_PLAYER_PROFILE_SUCCESS =
  "BACK_OFFICE_GET_PLAYER_PROFILE_SUCCESS";
export const BACK_OFFICE_GET_PLAYER_PROFILE_FAIL =
  "BACK_OFFICE_GET_PLAYER_PROFILE_FAIL";

export const BACK_OFFICE_REACTIVATE_PLAYER_REQUEST =
  "BACK_OFFICE_REACTIVATE_PLAYER_REQUEST";
export const BACK_OFFICE_REACTIVATE_PLAYER_SUCCESS =
  "BACK_OFFICE_REACTIVATE_PLAYER_SUCCESS";
export const BACK_OFFICE_REACTIVATE_PLAYER_FAIL =
  "BACK_OFFICE_REACTIVATE_PLAYER_FAIL";