import {Alert, Button} from "react-bootstrap";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPlayerProfile, reactivatePlayer} from "../actions/backOfficeAction";

const PlayerProfileDetails = ({playerProfile}) => {

  const dispatch = useDispatch();

  const reactivatePlayerProcessing = useSelector(
    (state) => state.backOfficeState.reactivatePlayerProcessing
  );
  const reactivatePlayerResult = useSelector(
    (state) => state.backOfficeState.reactivatePlayerResult
  );
  const reactivatePlayerError = useSelector(
    (state) => state.backOfficeState.reactivatePlayerError
  );

  async function reactivateClickedHandler(rewardsId) {
    console.log("Reactivate clicked for player ID: ", rewardsId);
    await dispatch(reactivatePlayer(rewardsId));
    dispatch(getPlayerProfile(rewardsId));
  }

  return (
    <div className="admin-screen-divider-div player-profile-details">
      <h4 className="player-profile-details-header">
        {playerProfile?.firstName} {playerProfile?.lastName}, Player ID: {playerProfile?.rewardsId}
      </h4>
      <div className="player-profile-details">
        <p>Birthday: {playerProfile?.dob}</p>
        <p>ZIP code: {playerProfile?.zipcode}</p>
          {playerProfile?.disabled &&
            <Alert variant='warning'>
              {playerProfile?.disabled ? "LOGIN DISABLED" : ""}
              <span className="reactivate-button-span">
                <Button variant="primary" size="sm" className="gcotg-button"
                    onClick={() => reactivateClickedHandler(playerProfile?.rewardsId)}
                    disabled={reactivatePlayerProcessing ? true : undefined}>
                Reactivate
                </Button>
              </span>
            </Alert>
          }
    </div>
    </div>
  );
}

export default PlayerProfileDetails;